import { Link } from "gatsby"
import React from "react"
import SEO from "../components/seo"
import "../styles/settings.scss"
import "../styles/style.scss"

const PrivacyPolicyPage = () => (
  <>
    <SEO title="Privacy Policy" />
    <div className="site-wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="about-wrapper">
              <div class="about-content">
                <h2>Privacy Policy</h2>
                <br />
                <p style={{ color: "rgba(255, 255, 255, 0.87)" }}>
                  Your data (workouts, preferences, routines and everything
                  else) is safely stored at Google servers via{" "}
                  <a href="https://firebase.google.com/" target="_blank">
                    Firebase
                  </a>
                  . Virtus is constantly improved for you and other users by
                  your actions (events) via Firebase Analytics.
                </p>
                <br />
                <p style={{ color: "rgba(255, 255, 255, 0.87)" }}>
                  When you log in the app (Google, Facebook or other) we use
                  <a
                    href="https://firebase.google.com/products/auth/"
                    target="_blank"
                  >
                    Firebase Authentication service
                  </a>{" "}
                  to manage the process and store your email. We do this to make
                  your experience more personalized and store your data so you
                  can use Virtus on all your devices.
                </p>
                <br />
                <p style={{ color: "rgba(255, 255, 255, 0.87)" }}>
                  Our promise to you is that we will never sell or share your
                  information with other 3rd party individuals/companies.
                  Please, check back this page for newer information regarding
                  your privacy.
                </p>
                <Link to="/">Go Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default PrivacyPolicyPage
